import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import global_config from '@/../config.json'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/language'
  },
  {
    path: '/language',
    name: 'LanguageSelection',
    component: () => import(/* webpackChunkName: "language" */ '../views/LanguageSelection/index.vue')
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () => import(/* webpackChunkName: "chat" */ '../views/Chat/index.vue')
  }
  // {
  //   path: '/disclaimer/:language',
  //   name: 'Disclaimer',
  //   component: () => import(/* webpackChunkName: "disclaimer" */ '../views/Disclaimer/index.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  try {
    const { language = null, response_option = null, user_id = null } = to.query
    const { default_language = null } = global_config
    const selected_language = store.state.i18n.socket_language

    let next_params = {}

    if (response_option) {
      store.commit('ADD_SOCKET_ADDITIONAL_QUERY_PARAMS', { response_option })
      delete to.query.response_option
      next_params = { ...to, query: { ...to.query } }
    }

    if (user_id) {
      store.commit('ADD_SOCKET_ADDITIONAL_QUERY_PARAMS', { user_id })
      delete to.query.user_id
      next_params = { ...to, query: { ...to.query } }
    }

    if (to === from) return // Avoid redirect to same page
    else if (from.name === 'Chat') return // Avoid user back to previous page from home page
    else if (to.name === 'Chat' && from.name !== 'LanguageSelection' && !selected_language) next_params = { path: '/language' } // Avoid user access chat page directly

    store.commit('SET_I18N_LANGUAGE', language || selected_language || default_language)
    if ((language || default_language) && to.name === 'LanguageSelection') next_params = { path: '/chat' }

    next(next_params)
  } catch (error) {
    console.log(`Route Error: ${error.message}`)
  }
})

export default router
